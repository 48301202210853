var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"cart-card-block show p-0 col-12",attrs:{"id":"address"}},[_c('div',{staticClass:"row align-item-center"},[_c('div',{staticClass:"col-lg-8"},[_c('div',{staticClass:"iq-card"},[_vm._m(0),_c('div',{staticClass:"iq-card-body"},[_c('form',{attrs:{"onsubmit":"required()"}},[_c('div',{staticClass:"row mt-3"},[_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6),_vm._m(7),_vm._m(8),_c('div',{staticClass:"col-md-6"},[_c('button',{staticClass:"btn btn-primary text-white",attrs:{"id":"savenddeliver","type":"submit"},on:{"click":function($event){return _vm.$router.push({name: 'store.store-checkoutaddress'})}}},[_vm._v("Save And Deliver Here")])])])])])])]),_c('div',{staticClass:"col-lg-4"},[_c('div',{staticClass:"iq-card"},[_c('div',{staticClass:"iq-card-body"},[_c('h4',{staticClass:"mb-2"},[_vm._v("Nik John")]),_vm._m(9),_c('hr'),_c('router-link',{staticClass:"btn btn-primary text-white d-block mt-1 next",attrs:{"id":"deliver-address","to":{name:'store.store-checkoutpayment'}}},[_vm._v("Deliver To this Address")])],1)])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"iq-card-header d-flex justify-content-between"},[_c('div',{staticClass:"iq-header-title"},[_c('h4',{staticClass:"card-title"},[_vm._v("Add New Address")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Full Name: *")]),_c('input',{staticClass:"form-control",attrs:{"type":"text","name":"fname","required":""}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Mobile Number: *")]),_c('input',{staticClass:"form-control",attrs:{"type":"text","name":"mno","required":""}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Flat, House No: *")]),_c('input',{staticClass:"form-control",attrs:{"type":"text","name":"houseno","required":""}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Landmark e.g. near apollo hospital:: *")]),_c('input',{staticClass:"form-control",attrs:{"type":"text","name":"landmark","required":""}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Town/City: *")]),_c('input',{staticClass:"form-control",attrs:{"type":"text","name":"city","required":""}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Pincode: *")]),_c('input',{staticClass:"form-control",attrs:{"type":"text","name":"pincode","required":""}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("State: *")]),_c('input',{staticClass:"form-control",attrs:{"type":"text","name":"state","required":""}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"addtype"}},[_vm._v("Address Type")]),_c('select',{staticClass:"form-control",attrs:{"id":"addtype"}},[_c('option',[_vm._v("Home")]),_c('option',[_vm._v("Office")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"shipping-address"},[_c('p',{staticClass:"mb-0"},[_vm._v("9447 Glen Eagles Drive")]),_c('p',[_vm._v("Lewis Center, OH 43035")]),_c('p',[_vm._v("UTC-5: Eastern Standard Time (EST)")]),_c('p',[_vm._v("202-555-0140")])])
}]

export { render, staticRenderFns }